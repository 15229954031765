import React from "react";
import { arrayOf, shape } from "prop-types";
import styled from "styled-components";
import {
  AlternateBackgroundItem,
  HtmlRenderer,
} from "@BrownUniversity/brown-react-library";
import { formatDate, getDurationTag } from "../helpers";
import { H2, H3, A, COLORS, FONTS, MEDIA } from "./shared_styles";

const statusMessages = (type) => {
  switch (type) {
    case "Open for Registration":
      return COLORS.darkEmerald;
    case "Nearing Capacity":
      return COLORS.navy;
    case "Waitlisted":
      return "#fd7d1a";
    default:
      return COLORS.red;
  }
};

function Offerings({ offerings }) {
  return (
    <div>
      {offerings.map((course, index) => {
        const { title, program, formats, dates, synopsis, code, status } =
          course;

        return (
          <AlternateBackgroundItem hoverDecoration index={index} key={code}>
            <Container>
              <A href={`/detail/${code}`}>
                <H2>
                  <HtmlRenderer HTMLstring={title} clean />
                </H2>
              </A>
              <DetailsWrapper>
                <DetailContainer>
                  <Title>Program</Title>
                  <SubTitle>{program}</SubTitle>
                </DetailContainer>
                <DateProgramContainer>
                  <Title>Dates & Formats</Title>
                  {dates.map((date, i) => {
                    const currentStatus = status[i] ?? status[0];
                    const currentFormat = formats[i] ?? formats[0];
                    return (
                      <div
                        key={`${date.startDate}-${title}-${code}-${
                          date.endDate
                        }-${Math.random()}`}
                      >
                        <SubTitle>
                          {formatDate(date.startDate)} -{" "}
                          {formatDate(date.endDate)}{" "}
                        </SubTitle>
                        <TagWrapper>
                          <Tags $color={COLORS.darkBrown}>
                            {getDurationTag(date.startDate, date.endDate)}
                          </Tags>
                          <Tags $color={COLORS.darkBrown}>{currentFormat}</Tags>
                          <Tags $color={statusMessages(currentStatus)}>
                            {currentStatus}
                          </Tags>
                        </TagWrapper>
                      </div>
                    );
                  })}
                </DateProgramContainer>
              </DetailsWrapper>
              <DescriptionContainer>
                <HtmlRenderer HTMLstring={synopsis} />
              </DescriptionContainer>
            </Container>
          </AlternateBackgroundItem>
        );
      })}
    </div>
  );
}

export default Offerings;

const Container = styled.div`
  padding: 2rem;
`;

const DateProgramContainer = styled.div`
  flex: 2;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const SubTitle = styled.p`
  ${FONTS.sans}
  color: ${COLORS.darkGrey};
  font-size: 0.85rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 1.25rem;
`;

const Title = styled(H3)`
  margin-bottom: 10px;
  font-size: 1rem;
`;

const DetailsWrapper = styled.div`
  margin-bottom: 2.25rem;
  display: flex;
  justify-content: space-between;

  ${MEDIA.tablet} {
    flex-direction: column;
  }
`;

const DetailContainer = styled.div`
  margin-right: 1rem;
  flex: 1;

  ${MEDIA.tablet} {
    margin: 0;
  }
`;

const DescriptionContainer = styled.div`
  ${FONTS.serif}
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  color: ${COLORS.darkGrey};
  overflow-wrap: break-word;
`;

const Tags = styled.small`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  min-width: 100px;
  width: 100%;
  font-size: 11px;
  max-height: 30px;
  ${FONTS.sans};

  &:before {
    content: "•";
    color: ${({ $color }) => $color};
    font-size: 2.5rem;
    margin: 0;
  }
`;

Offerings.propTypes = {
  offerings: arrayOf(shape({})).isRequired,
};
